import React, { useState, useEffect } from 'react';

const Webcam = () => {
	const [current, setCurrent] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			tick();
		}, 10500);

		return () => clearInterval(interval);
	}, [current]);

	const tick = () => {
		setCurrent(prevCurrent => (prevCurrent === 1 ? 0 : prevCurrent + 1));
	};

	const webcam = [
		"https://srv07.trinum.com/ibox/ftpcam/mega_les-arcs_arcabulle.jpg",
		"https://srv07.trinum.com/ibox/ftpcam/mega_les_arcs_aiguille.jpg",
	];

	let date = new Date();
	let code = date.getMonth() + '_' + date.getDate() + '_' + date.getHours();

	let style = {
		backgroundImage: 'url("' + webcam[current] + '?v=' + code + '")',
	};

	return  <div className="wallpaper" style={style}> </div> 
};

export default Webcam;
