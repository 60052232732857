import React, { useState, useEffect } from 'react';

const Clock = ({ isPaused }) => {
	const [date, setDate] = useState(new Date());

	
	const festive = [
		'0101',  // Nouvel An
		'1224',  // Noël
		'1225',  // Noël
		'1231',  // Noël
	];

	useEffect(() => {
		const timerID = setInterval(() => tick(), 100);

		return () => clearInterval(timerID);
	}, []);

	const tick = () => {
		setDate(new Date());
	};

	const formatTime = (time) => {
		return (time < 10) ? '0' + time : time;
	}

	const clockstyle = (time) => {
		if (time > 500)
			return { opacity: 0 };
		else
			return { opacity: 1 };
	}

	const jour = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
	const mois = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];

	const hours = formatTime(date.getHours());
	const minutes = formatTime(date.getMinutes());
	const style = clockstyle(date.getMilliseconds());

	const day = jour[date.getDay()];
	const date_num = date.getDate();
	const month = mois[date.getMonth()];
	const dt = `${formatTime(date.getMonth() + 1)}${formatTime(date.getDate())}`;
	const isFestive = festive.indexOf(dt) !== -1;

	return <div
		className={`is-flex is-align-items-center`}
		style={{ position: 'absolute', top: '0', left: '0' }}
	>
		<span className="time-block transilien">
			<span>{hours}</span>
			<span style={style}>:</span>
			<span>{minutes}</span>
		</span>
		<div className={`is-flex is-align-items-center gap-1 ${isPaused ? 'paused' : 'play'}`}>
			<b className='pause-block'>
				{isPaused ? '⏸' : '⏵'}
			</b>
			<b className="date-block">
				{day} {date_num} {month}
			</b>
		</div>
	</div>;
};

export default Clock;
