import React, { memo } from 'react';

import wind from './assets/img/svg/wind.svg';
import wet from './assets/img/svg/wet.svg';
import moisture from './assets/img/svg/moisture.svg';

const imgs = [
	'0000',
	'0101',  // Nouvel An
	'0214',  // St Valentin
	'0317',  // St Patrick
	'0501',  // 1er Mai
	'0508',  // Armistice
	'0606',  // DDay
	'0621',  // Fete de la musique
	'0714',  // Fete nationale
	'0921',  // Automne
	'1031',  // Halloween
	'1109',  // Chute du mur de Berlin
	'1111',  // Armistice
	'1221',  // Hiver
	'1224',  // Noël
	'1225',  // Noël
];

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const getFullDate = (timestamp) => {
	const days = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
	const date = new Date(timestamp * 1000);
	return `${days[date.getDay()]} ${date.getDate()}`;
};

const gstyle = (temp, max, min) => ({
	bottom: `${((temp - min) / (max - min)) * 100}px`,
});

const rstyle = (i, temp, temp2, max, min) => {
	const bottom = ((temp - min) / (max - min)) * 100;
	const bottom2 = ((temp2 - min) / (max - min)) * 100;

	const { c, angleC } = pythagoreanTheorem(55, bottom - bottom2);

	return {
		transform: `rotate(${angleC}deg)`,
		width: `${c}px`,
		bottom: `${(bottom + 1)}px`,
		transformOrigin: 'left',
	};
};

const pythagoreanTheorem = (a, b) => {
	const c = Math.sqrt(a * a + b * b);
	const angleC = Math.atan2(b, a) * (180 / Math.PI);
	return { c, angleC };
};

const getHour = (timestamp) => new Date(timestamp * 1000).getHours() + ":00";

const getMax = (all) => Math.max(...all.slice(0, 16).map(item => item.main.temp));

const getMin = (all) => Math.min(...all.slice(0, 16).map(item => item.main.temp));

const greetings = () => {
	const hour = new Date().getHours();
	if (hour >= 5 && hour < 12) return "Bonjour";
	if (hour < 18) return "Bon après-midi";
	if (hour < 22) return "Bonne soirée";
	return "Bonne nuit";
};

const isRainSoon = (weather) => {
	const hourly = weather.list;
	let rainDuration = 0;
	let isRainingNow = false;
	let freezeRisk = false;

	for (let i = 0; i < hourly.length && i <= 18; i++) {
		if (hourly[i].rain) {
			if (i === 0) {
				isRainingNow = true;
			}
			rainDuration += 3;
		} else if (rainDuration > 0) {
			break;
		}
		if (hourly[i].main.temp <= 0) {
			freezeRisk = true;
		}
	}

	let message = '';

	if (freezeRisk) {
		message += 'Risque de gel. ';
	}

	if (isRainingNow) {
		message = `${capitalizeFirstLetter(hourly[0].weather[0].description)} pendant encore ${rainDuration} heures.`;
	} else if (rainDuration > 0) {
		message = `${capitalizeFirstLetter(hourly[rainDuration].weather[0].description)} dans ${rainDuration} heures.`;
	}

	return message;
};

const BlockTemp = memo(({ temp, i, max, min }) => {
	const className = min === temp ? 'temp_min' : max === temp ? 'temp_max' : 'canva_nonblock_temp';
	return (
		<div className={`canva_block_temp ${className}`} style={gstyle(temp, max, min)}>
			{className && <b>{Math.round(temp)}</b>}°<b>C</b>
		</div>
	);
});

const Home = ({ weatherData }) => {
	if (!weatherData || !weatherData.list) {
		return (
			<>
				<div className='bottom-shadow'>
					<div className="title">
						<h1 className='my-0 mx-4'>{greetings()}, </h1>
					</div>
				</div>
			</>
		);
	}

	const weather = weatherData.list[1];

	return <>
		<div className='bottom-shadow'>
			<div className="title">
				<h1 className='my-0 mx-4'>{greetings()}, </h1>
				<p className='my-0 mx-4'>{isRainSoon(weatherData)}</p>
			</div>
		</div>
		<div className='header'>
			<div className='temperature px-4'>
				<span className='my-3 mx-3 description'>
					{capitalizeFirstLetter(weather.weather[0].description)}
				</span>
				<div className='is-flex is-align-items-baseline'>
					<span className='value'>{Math.round(weather.main.temp)}</span>
					<span>°C</span>
				</div>
			</div>
			<div className='details is-flex is-align-item-center p-3 gap-3'>
				<div className='is-flex is-align-item-center'>
					<img src={wind} className='white-svg det_img' />
					<span>{Math.round(weather.wind.speed)} Km/h</span>
				</div>
				<div className='is-flex is-align-item-center'>
					<img src={wet} className='white-svg det_img' />
					<span>{Math.round(weather.pop * 100)} %</span>
				</div>
				{weather.rain && weather.rain >= 1 && (
					<div className='is-flex is-align-item-center'>
						<img src={moisture} className='white-svg det_img' />
						<span>{Math.round(weather.rain)} mm</span>
					</div>
				)}
			</div>
		</div>
	</>;
};

const Weather = ({ weatherData }) => {
	if (!weatherData || !weatherData.list) {
		return null;
	}

	return (
		<table className="Weather">
			<tbody>
				<tr>
					{weatherData.list.slice(0, 36).map((weather, i) => (
						<React.Fragment key={i}>
							{(i + 2) % 8 === 0 && (
								<td key={i}>
									<div className='weather_block'>
										<br />
										<span>{getFullDate(weather.dt)}</span>
										<br />
										<img src={`weather/${weather.weather[0].icon}.png`} className='type' />
										<br /><br />
										<span className='temp'>{Math.round(weather.main.temp.day)}°<b>C</b></span>
										<br /><br />
										<div>
											<span className='temp_min'><b>{Math.round(weather.main.temp.min)}</b>°<b>C</b></span>
											<span className='temp_space'></span>
											<span className='temp_max'><b>{Math.round(weather.main.temp.max)}</b>°<b>C</b></span>
										</div>
										<br />
										<span>{capitalizeFirstLetter(weather.weather[0].description)}</span>
									</div>
									<div className='weather_details'>
										<img src={wind} className='white-svg det_img' />
										{Math.round(weather.wind_speed)} Km/h <br />
										<img src={wet} className='white-svg det_img' />
										{Math.round(weather.pop * 100)} % <br />
										{weather.rain && weather.rain >= 1 ? (
											<>
												<img src={moisture} className='white-svg det_img' />
												{Math.round(weather.rain)} mm <br />
											</>
										) : (
											<div style={{ height: 25 }} />
										)}
									</div>
								</td>
							)}
						</React.Fragment>
					))}
				</tr>
			</tbody>
		</table>
	);
}

const Graph = ({ weatherData }) => {
	if (!weatherData || !weatherData.list) {
		return null;
	}
	const max = getMax(weatherData.list);
	const min = getMin(weatherData.list);

	return <>
		<div className='weather-graph is-flex is-align-items-end'>
			{weatherData.list.slice(0, 18).map((weather, i) => (
				<div className='weather_block'>
					<BlockTemp
						weatherData={weatherData}
						temp={weather.main.temp}
						i={i}
						max={max}
						min={min}
					/>
					<div className='canva_point_temp' style={gstyle(weather.main.temp, max, min)}></div>
					<div className='canva_line' style={rstyle(i, weather.main.temp, weatherData.list[i + 1].main.temp, max, min)}></div>
				</div>
			))}
		</div>
		<div className='weather-graph is-flex is-align-items-end'>
			{weatherData.list.slice(0, 18).map((weather, i) => (
				<div className='canva_details'>
					{weatherData.list[i - 1] ? (
						weatherData.list[i - 1].weather[0].icon.replace("04", "03") === weather.weather[0].icon.replace("04", "03")
							?
							<div className="canva_line_horizontal_weather"><span></span></div>
							: <img src={'weather/' + weather.weather[0].icon + '.png'} />
					) : (
						<img src={'weather/' + weather.weather[0].icon + '.png'} />
					)}
					<div>
						{i % 2 === 0
							?
							<>
								<span className="canva_line_vertical_time"></span>
								<div className="canva_block_time">{getHour(weather.dt)}</div>
							</>
							:
							<>
								<span className="canva_line_none_time"></span>
								<div className="canva_block_time"></div>
							</>
						}
					</div>
				</div>
			))}
		</div>
	</>

	return (
		<table className="graph weather">
			<tbody>
				<tr>
					{weatherData.list.slice(0, 16).map((weather, i) => (
						<td>
							<div className='weather_block'>
								<BlockTemp
									weatherData={weatherData}
									temp={weather.main.temp}
									i={i}
									max={max}
									min={min}
								/>
								<div className='canva_point_temp' style={gstyle(weather.main.temp, max, min)}></div>
								<div className='canva_line' style={rstyle(i, weather.main.temp, weatherData.list[i + 1].main.temp, max, min)}></div>
								<div className='canva_details'>
									{weatherData.list[i - 1] ? (
										weatherData.list[i - 1].weather[0].icon.replace("04", "03") === weather.weather[0].icon.replace("04", "03")
											?
											<div className="canva_line_horizontal_weather"><span></span></div>
											: <img src={'weather/' + weather.weather[0].icon + '.png'} />
									) : (
										<img src={'weather/' + weather.weather[0].icon + '.png'} />
									)}
									<div>
										{i % 2 === 0
											?
											<>
												<span className="canva_line_vertical_time"></span>
												<div className="canva_block_time">{getHour(weather.dt)}</div>
											</>
											:
											<>
												<span className="canva_line_none_time"></span>
												<div className="canva_block_time"></div>
											</>
										}
									</div>
								</div>
							</div>
						</td>
					))}
				</tr>
			</tbody>
		</table>
	);
};

export default Home;
export { Weather, Graph };
